import styled from "styled-components"
import Check from "../../images/Check.png"
import BreakpointUp from "../Media/BreakpointUp"

export const SectionPage = styled.section`
  background-image: ${props => props.bgImg};
  background-color: ${props => props.bg};
  background-size: ${props => props.bgSize};
  height: ${props => props.ht};
  text-align: ${props => props.textAlign};
  position: relative;
  z-index: ${props => props.zIndex};
  padding-top: ${props => props.pt};
  padding-bottom: ${props => props.pb};
  overflow: ${props => props.overflow};
  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  &:before {
    display: ${props => (props.bgBefore ? "block" : "none")};
    top: ${props => props.topBefore};
    right: ${props => props.rightBefore};
    bottom: ${props => props.bottomBefore};
    left: ${props => props.leftBefore};
    background: ${props => props.bgBefore};
    background-size: ${props => props.bgBeforeSize};
    background-repeat: ${props => props.bgBeforeRepeat};
    width: ${props => props.bgBeforeWidth};
    height: ${props => props.bgBeforeHeight};
    opacity: ${props => props.opacityBefore};
  }
  &:after {
    display: ${props => (props.bgAfter ? "block" : "none")};
    top: ${props => props.topAfter};
    right: ${props => props.rightAfter};
    bottom: ${props => props.bottomAfter};
    left: ${props => props.leftAfter};
    background: ${props => props.bgAfter};
    background-size: ${props => props.bgAfterSize};
    background-repeat: ${props => props.bgAfterRepeat};
    width: ${props => props.bgAfterWidth};
    height: ${props => props.bgAfterHeight};
    opacity: ${props => props.opacityAfter};
  }
  @media (min-width: 992px) {
    padding-top: ${props => props.lpt};
    padding-bottom: ${props => props.lpb};
    margin-top: ${props => props.lmt};
  }
  @media (min-width: 1200px) {
    padding-top: ${props => props.xpt};
    padding-bottom: ${props => props.xpb};
  }
  @media (min-width: 1600px) {
    padding-top: ${props => props.ept};
    padding-bottom: ${props => props.epb};
  }
`

SectionPage.defaultProps = {
  top: "0",
  bgImg: "inherit",
  bgColor: "white",
  bgSize: "inherit",
  textAlign: "left",
  topAfter: "0",
  topBefore: "0",
}

export const SectionPageTitle = styled.h1`
  max-width: ${props => props.maxWidth};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  top: ${props => props.top};
  text-transform: ${props => props.textTransform};
  margin-right: auto;
  width: 100%;
  @media (min-width: 1200px) {
    margin-bottom: ${props => props.xmb};
  }
  @media (min-width: 1600px) {
    margin-bottom: ${props => props.xxmb};
  }
`
SectionPageTitle.defaultProps = {
  textAlign: "left",
  maxWidth: "100%",
  color: "#062C44",
  mt: "auto",
  ml: "auto",
  mb: "10px",
  top: "auto",
  textTransform: "inherit",
}

export const SectionHeading = styled.h2`
  max-width: ${props => props.maxWidth};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  top: ${props => props.top};
  text-transform: ${props => props.textTransform};
  margin-right: auto;
  width: 100%;
  @media (min-width: 1200px) {
    margin-bottom: ${props => props.xmb};
  }
  @media (min-width: 1600px) {
    margin-bottom: ${props => props.xxmb};
  }
`

SectionHeading.defaultProps = {
  textAlign: "left",
  maxWidth: "100%",
  color: "#062C44",
  mt: "auto",
  ml: "auto",
  mb: "10px",
  top: "auto",
  textTransform: "inherit",
}

export const Section = styled.div`
  padding-top: ${props => props.pt};
  padding-bottom: ${props => props.pb};
  background: ${props => props.bg};
  margin-top: ${props => props.mt};
  position: relative;
  > p {
    color: #010920;
    max-width: 774px;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-left: ${props => props.ml};
  }
  @media (max-width: 992px) {
    padding: 100px ${props => props.mplr};
    padding-top: ${props => props.mtp};
    padding-bottom: ${props => props.mbp};
  }
  @media (max-width: 568px) {
    padding: 50px ${props => props.mplr};
    padding-top: ${props => props.mtp};
    padding-bottom: ${props => props.mbp};
  }
`
Section.defaultProps = {
  bg: "white",
  pt: "150px",
  pb: "130px",
  mplr: "10px",
}

export const BreadCrumb = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #1e4156;
  @media (min-width: 1200px) {
    position: absolute;
    top: ${props => props.top};
    z-index: 20;
  }
  & span {
    color: #676b79;
    font-weight: 400;
  }
  & a {
    color: #1e4156;
  }
  @media (max-width: 768px) {
    top: ${props => props.mTop};
  }
  &.static {
    position: static !important;
  }
`
BreadCrumb.defaultProps = {
  top: "214px",
}

export const HeroTitle = styled.h1`
  max-width: ${props => props.maxWidth};
  margin: auto;
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  font-size: 34px;
  line-height: 44px;
  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 54px;
  }
  @media (min-width: 1600px) {
    font-size: 54px;
    line-height: 64px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
HeroTitle.defaultProps = {
  textAlign: "center",
  mb: "15px",
  color: "#062c44",
  maxWidth: "780px",
}

export const SectionTitle = styled.h2`
  max-width: ${props => props.maxWidth};
  width: 100%;
  margin: auto;
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  /* font-size: 24px;
	line-height: 32px;
	@media (min-width: 768px) {	
		font-size: 34px;
		line-height: 44px;	
	}
	@media (min-width: 992px) {	
		font-size: 54px;
		line-height: 64px;	
	} */
`

SectionTitle.defaultProps = {
  textAlign: "center",
  mb: "15px",
  color: "#062c44",
  maxWidth: "1120px",
}

export const SectionDescription = styled.div`
  max-width: ${props => props.maxWidth};
  width: 100%;
  margin: 0 auto ${props => props.mb} auto;
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
  > p {
    color: ${props => props.color};
  }
`
SectionDescription.defaultProps = {
  textAlign: "center",
  maxWidth: "1120px",
  mb: "30px",
  color: "#010920",
}

export const RightPannel = styled.div`
  display: inline-block;
  vertical-align: text-top;
  width: 430px;
  margin-left: 50px;
  margin-top: ${props => props.mt};

  @media (min-width: 1024px) {
    position: sticky;
    top: 85px;
  }
  @media (min-width: 1151px) {
    top: 165px;
  }

  @media (max-width: 1024px) {
    width: 430px;
    margin: auto;
    margin-top: 100px;
    display: block;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`

export const LeftPannel = styled.div`
  display: inline-block;
  vertical-align: text-top;
  width: calc(100% - 480px);
  @media (max-width: 1024px) {
    width: 100%;
    display: block;
  }
`

export const MarkdownContent = styled.div`
  counter-reset: subsection;
  & h2,
  & h3,
  & h4,
  & h5 {
    & a {
      display: none;
    }
  }

  & h2 {
    + h3 {
      padding-top: 20px;
    }
  }

  & h3 {
    margin-bottom: 5px;
  }

  & p {
    text-align: ${props => props.textAlign};
    color: ${props => props.color};
    margin: 0 0 15px 0;
    max-width: 100%;
    > a {
      color: #ea5f2c;
      &:hover {
        color: #010920;
      }
    }
    + h2,
    + h3 {
      padding-top: 15px;
      @media (min-width: 992px) {
        padding-top: 30px;
      }
    }
    & a[href^="tel:"] {
      color: #0024ee;

      &:hover,
      &:focus,
      &:active {
        color: #ff3e00;
      }
    }
  }

  & ul {
    list-style: none;
    margin: 0 0 0 20px;
    & li {
      vertical-align: text-top;
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      color: #1e4156;
      margin: 0 0 10px;
      padding: 0;
      &:before {
        content: url(${Check});
        display: inline-block;
        margin-left: -20px;
        width: 20px;
        vertical-align: top;
      }
    }
    + h2,
    + h3 {
      padding-top: 15px;
      @media (min-width: 992px) {
        padding-top: 30px;
      }
    }
  }
`
MarkdownContent.defaultProps = {
  textAlign: "left",
}

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${props => props.my} 0;
  border-bottom: 1px solid ${props => props.borderColor};
`
Divider.defaultProps = {
  my: "20px",
  borderColor: "rgba(1, 9, 32, 0.1)",
}

export const StepWrapper = styled.div`
  position: absolute;
  width: 50px;
  color: ${props => props.color};
  top: ${props => props.top};
  left: ${props => props.left};
  text-align: center;
  @media (max-width: 1600px) {
    left: 10px;
  }
  & strong {
    font-size: 24px;
    color: ${props => props.color};
    margin-bottom: 0px;
  }
  & svg {
    margin-top: 10px;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`

StepWrapper.defaultProps = {
  top: "200px",
  color: "#FFFFFF33",
  left: "calc(50vw - 780px)",
}

export const HalfStep = styled.div`
  position: absolute;
  left: 23px;
`

export const OR = styled.span`
  position: absolute;
  width: 80px;
  height: 80px;
  padding-top: 16px;
  border-radius: 80px;
  border: 2px solid white;
  color: white;
  font-size: 34px;
  line-height: 41px;
  font-family: Chakra Petch;
  font-weight: 700;
  top: 80px;
  left: calc(100% - 40px);
  text-align: center;
  z-index: 20;
`

export const SectionButtonWrapper = styled.div`
  text-align: ${props => props.textAlign};
  margin-top: ${props => props.mt};
  & a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    margin-top: ${props => props.mmt};
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`
SectionButtonWrapper.defaultProps = {
  mt: "50px",
  textAlign: "center",
}

export const UspsDot = styled.div`
  margin-left: ${props => props.ml};
  z-index: 5;
  @media (max-width: 1400px) {
    margin-left: ${props => props.sml};
    z-index: 5;
  }
`

export const MobileUspsDot = styled.div`
  margin-top: ${props => props.mt};
  z-index: 5;
`

export const RightSideStep = styled.div`
  position: absolute;
  width: 200px;
  text-align: right;
  top: 0%;
  padding-top: ${props => props.pt};
  height: 60%;
  right: -80px;
  color: #676b79;
  z-index: 11;
  @media (max-width: 1500px) {
    right: 0;
  }
  & div {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;

    margin-bottom: 13px;
    & span {
      padding: 3px 8px;
      border-radius: 20px;
      border: 1px solid;
      margin-left: 5px;
    }
  }
`
RightSideStep.defaultProps = {
  pt: "10%",
}

export const LeftTriangle = styled.div`
  position: relative;
  z-index: 50;
  border-top: 450px solid rgba(255, 255, 255, 0.01);
  border-left: 55vw solid rgba(119, 168, 196, 0.5);
  margin-top: -305px;
  opacity: 0.2;
  @media (max-width: 768px) {
    border-top: 350px solid rgba(255, 255, 255, 0.01);
  }
`
export const RightTriangle = styled.div`
  position: relative;
  z-index: 50;
  border-top: 450px solid rgba(255, 255, 255, 0.01);
  border-right: 55vw solid rgba(119, 168, 196, 0.5);
  margin-top: -450px;
  opacity: 0.2;
  @media (max-width: 768px) {
    border-top: 350px solid rgba(255, 255, 255, 0.01);
    margin-top: -350px;
  }
`

export const AboutWrapper = styled.div`
  padding-top: 250px;
  padding-bottom: 70px;
  @media (max-width: 1200px) {
    padding-top: 170px;
  }
`
export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${props => props.pr};
  padding-left: ${props => props.pl};
  max-width: ${props => props.maxWidth};
  overflow: ${props => props.overflow};
`
Container.defaultProps = {
  maxWidth: "1350px",
  overflow: "inherit",
  pr: "15px",
  pl: "15px",
}

export const BaseButton = styled.span`
	position:relative;
	display: inline-flex;	
	justify-content: center;	
	text-align:center;
	border-radius:5px;
	transition: 0.5s ease-in-out;
	overflow:hidden;
    letter-spacing: 0.5px;
	
	font-family: Roboto;
	font-weight:700;
	border:1px solid transparent;
	font-size:16px;
	line-height: 28px;
	padding:3px 20px;
	@media(min-width:1200px){  
		padding:5px 20px;
	}
	.icon{
		&:empty {
			display: none;
		}
		+ .text{
			margin-left:15px;
		}
	}
	& .icon, & .text{
		position: relative;
    	z-index: 2;
		display: inline-flex;
		align-items: center;
		justify-content: center;	
	}
	&:before,
    &:after {
        content: '';
        position: absolute;
        transition: 0.5s ease-in-out;
        z-index: 1;
        top: 0;
    }	
	&:before {
		left: 0px;
		transform: translateX(0%);
		transition: 0.5s ease-in-out;

		border-right: 36px solid transparent;
		border-top: 36px solid transparent;
		width: calc(50% + 18px);
		@media(min-width:1200px){  
			border-right: 40px solid transparent;
			border-top: 40px solid transparent;
			width: calc(50% + 20px);
		}
	}
	
	&:after {
		right: 0px;
		transform: translateX(0%);
		transition: 0.5s ease-in-out;
		border-left: 36px solid transparent;
		border-bottom: 36px solid transparent;
		width: calc(50% + 18px);
		@media(min-width:1200px){  
			border-left: 40px solid transparent;
			border-bottom: 40px solid transparent;
			width: calc(50% + 20px);
		}
	}
	&:hover{
	    &:before { 
	    	transform: translateX(-100%);
	    	transition: 0.5s ease-in-out;
	    }
	    &:after { 
	    	transform: translateX(100%);
	    	transition: 0.5s ease-in-out;
	    }
	}
`

export const DropSearch = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.3);
  width: 298px;
  top: 100%;
  z-index: 2;
  text-align: left;
  overflow-y: auto;
  max-height: 310px;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ebebeb;
  }
  &::-webkit-scrollbar-thumb {
    background: #6d6d6d;
    border-radius: 4px;
  }
`
export const DropSearchBody = styled.div`
  padding: 10px 0;
`
export const DropSearchItem = styled.div`
  padding: 0 20px;
  border-bottom: 1px solid rgb(227, 233, 242);
  &:last-child {
    border-bottom: none;
  }
  & a {
    display: flex;
    align-items: center;
    color: #444;
    font-weight: 500;
    justify-content: space-between;
    padding: 8px 0;
    font-size: 14px;
    line-height: 22px;

    & svg {
      fill: #444;
      transition: transform 0.3s ease-out;
      width: 12px;
      display: none;
      ${BreakpointUp.xl`
				display:block;
			`}
    }
    &:hover {
      color: #000;
      svg {
        transform: translateX(4px);
        fill: #000;
      }
    }
  }
`

export const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  @media (min-width: 1200px) {
    margin: 0 -20px;
  }
`
export const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  @media (min-width: 1200px) {
    padding: 0 20px;
  }
`
export const RightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  @media (min-width: 1200px) {
    padding: 0 20px;
  }
`
export const ExploreMore = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  margin-right: ${props => props.mr};
  margin-left: ${props => props.ml};
`

export const ExploreItemMore = styled.div`
  position: relative;
  padding-left: ${props => props.pl};
  padding-right: ${props => props.pr};
`

export const Checkbox = styled.div`
  margin-bottom: 15px;
  label {
    [type="checkbox"] {
      width: 14px;
      height: 14px;
    }
  }
`
export const HelpText = styled.div`
  font-size: 12px;
  line-height: 20px;
`

export const FormCol = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 15px;
  }
`

export const Field = styled.div`
  position: relative;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    background: #fff;
    border: 0px;
    border-bottom: 1px solid #dce7ef;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #000;

    &::placeholder {
      color: rgba(103, 107, 121, 0.4);
    }
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: #062c44;
      &::placeholder {
        color: rgba(103, 107, 121, 0.4);
      }
    }
  }
  textarea.form-control {
    resize: none;
  }
`

export const MainPanelGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
`
export const LeftPanelGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
  }
`
export const RightPanelGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 992px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 75%;
    max-width: 75%;
    padding: 0 15px;
  }
`