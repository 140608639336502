import React from "react"
import { Link } from "gatsby"

import { RedLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { Divider } from "../../Section"
import { DropWrapper, Left, DropItem, Right, Triangle } from "./tags-component"

class OtherBuildingsDropdown extends React.Component {
  render() {
    return (
      <DropWrapper>
        <Right width="450px" ml="-450px">
          <h3>Steel Buildings</h3>
          <p>
            Commercial Steel building is a versatile solution for a business.
            Every commercial operation requires a unique set of building
            features, and our commercial metal buildings are designed according
            to use.
          </p>
          <RedLink to="/steel-buildings/">
            Steel Buildings
            <ArrowIcon fill="#ED602B" />
          </RedLink>
        </Right>
        <Left width="350px" ml="-10px">
          <Triangle left="28%" />
          <DropItem>
            <Link to="/utility-buildings/">
              Utility Buildings <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/storage-buildings/">
              Storage Buildings <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/commercial-metal-buildings/">
              Commercial Buildings <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/metal-building-homes/">
              Metal Building Homes <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/metal-building-prices/">
              Metal Buildings Prices <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/steel-buildings/metal-buildings-kits/">
            Metal Building Kits <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/metal-sheds/">
              Metal Sheds <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/metal-warehouses/">
            Metal Warehouses <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          {/* <Divider />
          <DropItem>
            <Link to="/products/">
            All Building Products <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem> */}
        </Left>
      </DropWrapper>
    )
  }
}

export default OtherBuildingsDropdown
