import styled from 'styled-components'

export const CustomBuilding = styled.div`
	position: relative;
	height: 850px;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	background: rgba(30, 65, 86, 0.98);
	padding-top: 78px;
	padding-left: calc(50vw - 675px);
	padding-right: 131px;
	& h5 {
		margin-top: 30px;
		font-size: 20px;
		line-height: 24px;
		color: white;
		margin-bottom: 20px;
	}
	& input {
		background: #1E4156;
		border: 1px solid rgba(255, 255, 255, 0.2);
		padding: 17px 26px;
		font-size: 16px;
		line-height: 26px;
		width: 100%;
		border-radius: 30px;
		color: white;
		margin-bottom: 20px;
		&:focus {
			outline: none;
		}
	}
	@media(max-width: 1500px){
		padding: 78px 5% 150px 7%;
	}
	@media(max-width: 1424px){
		padding: 78px 5% 150px 6%;
		padding-right: 10px;
		& h2 {
			padding-right: 10%;
		}
	}
	@media(max-width: 1200px){
		display: block;
		margin: auto;
		width: 100%;
		height: auto;
		padding: 58px 5% 138px;
	}
	@media(max-width: 480px) {
		& h2 {
			text-align: center;
		}
	}
`
export const ProductFormWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: auto;
	& h2 {
		font-size: 35px;
		line-height: 48px;
		color: white;
	}
	& input {
		background: #1E4156;
		border: 1px solid rgba(255, 255, 255, 0.2);
		padding: 17px 26px;
		font-size: 16px;
		line-height: 26px;
		width: 100%;
		border-radius: 30px;
		color: white;
		margin-bottom: 20px;
		&:focus {
			outline: none;
		}
	}
	@media(max-width: 1024px) {
		grid-template-columns: repeat(1, 1fr);
	}
`
export const LeftWrapper = styled.div`
	position: relative;
	min-height: 800px;
	background: #1E4156;
	padding: 58px 70px 150px;
	padding-left: calc(50vw - 675px);
	@media(max-width: 1500px){
		padding-left: 15%;
		padding-right: 5%;
	}
	@media(max-width: 1400px){
		padding-left: 10%;
		padding-right: 10px;
	}
	@media(max-width: 1200px){
		padding-left: 5%;
		padding-right: 10px;
	}
	& h5 {
		margin-top: 50px;
		font-size: 20px;
		line-height: 24px;
		color: white;
		margin-bottom: 20px;
	}
	& p {
		margin-top: 11px;
		color: white;
	}
	& a {
		color: white;
		font-weight: bold;
		& svg {
			margin-left: 5px;
			transition: ease 0.3s;
		}
		& :hover {
			cursor: pointer;
			& svg {
				margin-left: 10px;
			}
		}
	}
	@media(max-width: 480px) {
		height: auto;
		& h2 {
			text-align: center;
		}
		& h5 {
			margin-top: 30px;
		}

	}
`
export const RightWrapper = styled.div`
	height: 100;
	background: #010920;
	padding: 58px 70px 150px;
	padding-right: calc(50vw - 800px);
	@media(max-width: 1700px){
		padding-left: 5%;
		padding-right: 5%;
	}
	& h3 {
		margin-bottom: 30px;
	}
	& h5 {
		margin-top: 40px;
		color: white;
	}
	& .gatsby-image-wrapper {
		height: 450px;
	}
	@media(max-width: 480px) {
		padding: 50px 15px 80px;
	}
`
export const Triangle = styled.div`
  position: absolute;
  top: 65px;
  right: -15px;
	border-top: 30px solid rgba(0, 0, 0, 0.001);
	border-right: 30px solid #1E4156;
	transform: rotate(-45deg);
	@media(max-width: 768px) {
		display: none;
	}
`
export const TypeButton = styled.a`
	padding: 17px 25px;
	color: white;
	display: inline-block;
	border: 1px solid white;
	border-radius: 30px;
	margin-top: 20px;
	margin-right: 10px;
	& :hover {
		cursor: pointer;
		background: #EA5F2C;
		border: 1px solid #EA5F2C;
	}
`

export const ButtonWrapper = styled.div`
	position: absolute;
	z-index: 53;
	bottom: ${props=>props.bottom};
	text-align: ${props=>props.textAlign};
	width: 90%;
	max-width: 550px;
	@media(max-width: 1200px){
		bottom: ${props=>props.mBottom};
		text-align: center;
		max-width: 100%;
	}
`
ButtonWrapper.defaultProps={
	mt: "50px",
	bottom: "100px",
	mBottom: "80px",
	textAlign: "center"
}

export const Button = styled.button`
	position: relative;
  display: inline-block;
  width: ${props=>props.width};
	max-width: ${props=>props.maxWidth};
	background: #010920;
  overflow: hidden;
  height: 64px;
  border: 2px solid #010920;
	border-radius: 10px;
	text-align: center;
	margin: auto;
	margin-right: 10px;
  transition: 0.5s ease-in-out;
  & :focus {
  	outline: none;
  }
  & div {
  	z-index: 10;
  	padding: 12px;
		color: white;
		font-size: 24px;
		line-height: 29px;
		font-family: Chakra Petch;
		font-weight: 700;
		position: relative;
		transition: 0.5s ease-in-out;
  }
  &:before,
  &:after {
    content: '';
   	position: absolute;
   	transition: 0.5s ease-in-out;
   	z-index: 1;
   	top: 0;
  }

  &:before {
    left: 0px;
    border-right: 50px solid transparent;
    border-top: 64px solid #1E4156;
    width: calc(50% + 26px);
    transform: translateX(0%);
    transition: 0.5s ease-in-out;
  }
  
  &:after {
    right: 0px;
    border-left: 50px solid transparent;
    border-bottom: 64px solid #062C44;
  	width: calc(50% + 26px);
    transform: translateX(0%);
    transition: 0.5s ease-in-out;
  }

  &:hover {
  	border: 2px solid #ED602B;
  	cursor: pointer;
  	background: #ED602B;
  	& div {
  		color: white;	
  	}
    &:before { 
    	transform: translateX(-100%);
    	transition: 0.5s ease-in-out;
    }
    &:after { 
    	transform: translateX(100%);
    	transition: 0.5s ease-in-out;
    }
  }
  @media(max-width: 1024px) {
		&:hover {
			border: 2px solid #010920;
			background: #010920;
			& div {
				color: white;
			}
			&:before { 
	    	transform: none;
	    }
	    &:after { 
	    	transform: none;
	    }
		}
	}
  @media(max-width: 500px) {
  	width: ${props=>props.mWidth};
  }
`

Button.defaultProps = {
	width: "250px",
	mWidth: "150px"
}

export const Description = styled.div`
	font-size: 20px;
	line-height: 30px;
	font-weight: 800;
	color: ${props=>props.color};
	margin-bottom: 50px;
	margin-top: 70px;
	text-align: ${props=>props.textAlign};
	@media(max-width: 480px) {
		text-align: center;
	}
`
Description.defaultProps = {
	color: "white"
}
export const Types = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
  @media(max-width: 480px) {
  	margin-bottom: 30px;
  }
`
export const Label = styled.label`
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid ${props=>props.borderColor};
  margin-right: 10px;
  margin-bottom: 20px;
  color: ${props=>props.color};
  background: ${props=>props.bg};
  & :hover {
    border: 1px solid #EA5F2C;
    background: #EA5F2C;
    color: white;
    cursor: pointer;
  }
  & input[type="radio"] {
    display: none !important;
  }
  @media(max-width: 500px) {
    margin-right: 5px;
    padding: 10px 13px;
  }
`
Label.defaultProps = {
	color: "#FFFFFF33"
}
export const Dimensions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  & svg {
    vertical-align: top;
    margin-bottom: 10px;
    height: 70px;
  }
`
export const DimensionItem = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & div {
  	margin-left: 10%;
  }
  & input {
    border-radius: 30px !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    text-align: center;
  }
  & b {
    top: 0px !important;
    bottom: 20px !important;
    background: none !important;
    border: none !important;
    width: 50px !important;
    & i {
      background: white !important;
    }
  }
  @media(max-width: 1200px) {
  	& div {
	  	margin-left: 12%;
	  }
  	& svg {
  		margin-left: 25%;
  	}
  }
  @media(max-width: 500px) {
    display: block;
    & div {
	  	margin-left: 0;
	  	text-align: center;
	  }
  	& svg {
  		margin-left: 0;
  	}
  }
`
export const Value = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  text-align: center;
  & p {
    color: white;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  & h3 {
    color: white;
  }
  @media(max-width: 440px) {
    margin-left: 0;
  }
`
export const SideGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  color: white;
  & input {
    width: 23px !important;
  }
  @media(max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    width: 280px;
    margin: auto;
    grid-row-gap: 20px;
  }
`

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 23px;

  & input {
    margin-bottom: 17px;
  }

  @media(max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Success = styled.div`
  text-align: center;
  margin-top: 150px;
`

export const CustomValidationMessage = styled.div`
	position: absolute;
	display: ${props=>props.display};
	top: ${props=>props.top};
	left: ${props=>props.left};
	width: fit-content;
	background: white;
	padding: 5px 10px;
	border-radius: 5px;
	margin-top: -15px;
	margin-left: 20px;
	color: #ED602B;
	font-size: 14px;
	font-weight: 600;
	box-shadow: 1px 2px 20px rgba(0,0, 0, 0.2);
`


export const RequiredTriangle = styled.div`
	position: absolute;
	top: -7px;
	left: 20px;
	border-top: 15px solid white;
	border-right: 15px solid white;
	transform: rotate(-45deg);
`
