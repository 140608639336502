import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import PlusIcon from "../Icons/PlusIcon"
import HouseIcon from "../Icons/HouseIcon"
import SearchIcon from "../Icons/SearchIcon"
import PhoneIcon from "../Icons/PhoneIcon"
import HeaderSearchBox from "../SearchBox/HeaderSearchBox"
import SecondaryButton from "../Button/SecondaryButton"
import MobileHamburger from "../../images/hamburger.png"
import Logo from "../../images/logo.svg"

import CarportsDropdown from "./DropDown/carports"
import GaragesDropdown from "./DropDown/garages"
import RVCoversDropdown from "./DropDown/rv-covers"
import BarnsDropdown from "./DropDown/barns"
import OtherBuildingsDropdown from "./DropDown/other-buildings"
import BuyingGuideDropdown from "./DropDown/buying-guide"
import AboutDropdown from "./DropDown/about"

import CarportsMobileNav from "./MobileNav/carports-mobile"
import GaragesMobileNav from "./MobileNav/garages-mobile"
import RvCoversMobileNav from "./MobileNav/rvcovers-mobile"
import BarnsMobileNav from "./MobileNav/barns-mobile"
import OtherBuildingsMobileNav from "./MobileNav/other-buildings"
import BuyingGuideMobileNav from "./MobileNav/buying-guide"
import AboutProbuiltMobileNav from "./MobileNav/about-probuilt"
import ItemHover from "../ItemHover"
import ShopGif from "../../images/shop.gif";

const HeaderWrapper = styled.header`
  opacity: 1;
  height: 118px;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid #ddd;
  padding-top: 12px;
  @media (max-width: 1200px) {
    height: 70px;
    padding-top:0;
  }
`
const DesktopNav = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`
const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -12px;
  
`

const LogoLink = styled(Link)`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  z-index: 100;
  @media (max-width: 1200px) {
    display: flex;
    vertical-align: middle;
    height: 70px;
    align-items: center;    
  }


`
const TopNav = styled.div`
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content: flex-end;
  margin:0 -5px;
`
const TopItemNav = styled.div`
  position:relative;
  padding:0 5px;
`


const Nav = styled.div`
  width: calc(100% - 199px);
  display: inline-block;
  vertical-align: middle;
  background: #062c44;
  padding: 15px;
  padding-bottom: 0px;
  margin-top: -3px;
  margin-left: -1px;
  @media (max-width: 1200px) {
    display: none;
  }
`
const NavGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  align-items: flex-start;
  grid-column-gap: 1rem;
`
const NavItem = styled.div`
  text-align: center;
  color: ${props => props.color};
  font-family: Chakra Petch;
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
  & :hover {
    cursor: pointer;
  }
  & svg {
    transform: ${props => props.rotate};
    transition: ease 0.3s;
  }
`
NavItem.defaultProps = {
  color: "white",
}
const MobileNav = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: flex;  
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
  }
`
const MobileNavigation = styled.div`
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  & span {
    margin-left: 10px;
  }
  & a {
    + a{
      margin-left: 12px;
    }
  }
  .shop{
    width:80px;
  }
`
const DropDown = styled.div`
  top: 0;
  transition: opacity 0.5s ease;
  display: ${props => props.display};
  opacity: ${props => props.opacity};
`
const MobileNavToggle = styled.div`
  position: absolute;
  display: none;
  top: 70px;
  right:0;
  height: 100vh;
  background: white;
  z-index: 99;
  transition: ease 0.3s;
  padding: 50px 20px;
  @media (max-width: 1200px) {
    display: block;
    width: ${props => props.width};
    transform: ${props => props.transform};
    box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 30px 0;
  }
`

const MobileNavContainer = styled.div`
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
`

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisibleMobileNav: false,
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleRvCovers: false,
      isVisibleOtherBuildings: false,
      isVisibleBuyingGuide: false,
      isVisibleAbout: false,
    }
    this.handleMobileNav = this.handleMobileNav.bind(this)
    this.openCarports = this.openCarports.bind(this)
    this.openGarages = this.openGarages.bind(this)
    this.openBarns = this.openBarns.bind(this)
    this.openRvCovers = this.openRvCovers.bind(this)
    this.openOtherBuildings = this.openOtherBuildings.bind(this)
    this.openBuyingGuide = this.openBuyingGuide.bind(this)
    this.openAbout = this.openAbout.bind(this)
  }
  handleMobileNav() {
    let flag = !this.state.isVisibleMobileNav
    this.setState({
      isVisibleMobileNav: flag,
    })
  }
  openCarports() {
    let flag = !this.state.isVisibleCarports
    this.setState({
      isVisibleCarports: flag,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleRvCovers: false,
      isVisibleOtherBuildings: false,
      isVisibleBuyingGuide: false,
      isVisibleAbout: false,
    })
  }
  openGarages() {
    let flag = !this.state.isVisibleGarages
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: flag,
      isVisibleBarns: false,
      isVisibleRvCovers: false,
      isVisibleOtherBuildings: false,
      isVisibleBuyingGuide: false,
      isVisibleAbout: false,
    })
  }
  openBarns() {
    let flag = !this.state.isVisibleBarns
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: flag,
      isVisibleRvCovers: false,
      isVisibleOtherBuildings: false,
      isVisibleBuyingGuide: false,
      isVisibleAbout: false,
    })
  }
  openRvCovers() {
    let flag = !this.state.isVisibleRvCovers
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleRvCovers: flag,
      isVisibleOtherBuildings: false,
      isVisibleBuyingGuide: false,
      isVisibleAbout: false,
    })
  }
  openOtherBuildings() {
    let flag = !this.state.isVisibleOtherBuildings
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleRvCovers: false,
      isVisibleOtherBuildings: flag,
      isVisibleBuyingGuide: false,
      isVisibleAbout: false,
    })
  }
  openBuyingGuide() {
    let flag = !this.state.isVisibleBuyingGuide
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleRvCovers: false,
      isVisibleOtherBuildings: false,
      isVisibleBuyingGuide: flag,
      isVisibleAbout: false,
    })
  }
  openAbout() {
    let flag = !this.state.isVisibleAbout
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleRvCovers: false,
      isVisibleOtherBuildings: false,
      isVisibleBuyingGuide: false,
      isVisibleAbout: flag,
    })
  }
  render() {
    const { hideSearch } = this.props;
    return (
      <HeaderWrapper>
        <div className="container">
          <DesktopNav>
            <TopNav>
              {!hideSearch && <TopItemNav><HeaderSearchBox /></TopItemNav>}
              <TopItemNav>
                <Link to="/shop-metal-buildings"><img src={ShopGif} alt="Probuilt Steel Buildings" /></Link>
              </TopItemNav>
              <TopItemNav>
                <a href="https://probuiltsteel.sensei3d.com/" target="_blank" rel="noreferrer" className="btn-sm" aria-label="3D Designer"><SecondaryButton text="3D Designer" icon={<HouseIcon  stroke="#ED602B"/>}/></a>
              </TopItemNav>
              <TopItemNav>
                <a href="tel:8777541818" className="btn-sm" aria-label="(877) 754-1818"><SecondaryButton text="(877) 754-1818" icon={<PhoneIcon />}/></a>
              </TopItemNav>
            </TopNav>
            <HeaderInner>
              <LogoLink to="/" aria-label="desktop logo">
                <img src={Logo} width="200" height="105" alt="desktop logo" />
              </LogoLink>
              <Nav>
                <NavGrid>
                  <ItemHover>
                    {hoverItem => (
                      <NavItem
                        color={hoverItem ? "#ED602B" : "white"}
                        rotate={hoverItem ? "rotate(45deg)" : "rotate(0deg)"}
                      >
                        Carports{" "}
                        <PlusIcon fill={hoverItem ? "#ED602B" : "#98dfff"} />
                        <DropDown
                          display={hoverItem ? "block" : "none"}
                          opacity={hoverItem ? "1" : "0"}
                        >
                          <CarportsDropdown />
                        </DropDown>
                      </NavItem>
                    )}
                  </ItemHover>
                  <ItemHover>
                    {hoverItem => (
                      <NavItem
                        color={hoverItem ? "#ED602B" : "white"}
                        rotate={hoverItem ? "rotate(45deg)" : "rotate(0deg)"}
                      >
                        Garages{" "}
                        <PlusIcon fill={hoverItem ? "#ED602B" : "#98dfff"} />
                        <DropDown
                          display={hoverItem ? "block" : "none"}
                          opacity={hoverItem ? "1" : "0"}
                        >
                          <GaragesDropdown />
                        </DropDown>
                      </NavItem>
                    )}
                  </ItemHover>
                  <ItemHover>
                    {hoverItem => (
                      <NavItem
                        color={hoverItem ? "#ED602B" : "white"}
                        rotate={hoverItem ? "rotate(45deg)" : "rotate(0deg)"}
                      >
                        RV Covers{" "}
                        <PlusIcon fill={hoverItem ? "#ED602B" : "#98dfff"} />
                        <DropDown
                          display={hoverItem ? "block" : "none"}
                          opacity={hoverItem ? "1" : "0"}
                        >
                          <RVCoversDropdown />
                        </DropDown>
                      </NavItem>
                    )}
                  </ItemHover>
                  <ItemHover>
                    {hoverItem => (
                      <NavItem
                        color={hoverItem ? "#ED602B" : "white"}
                        rotate={hoverItem ? "rotate(45deg)" : "rotate(0deg)"}
                      >
                        Barns{" "}
                        <PlusIcon fill={hoverItem ? "#ED602B" : "#98dfff"} />
                        <DropDown
                          display={hoverItem ? "block" : "none"}
                          opacity={hoverItem ? "1" : "0"}
                        >
                          <BarnsDropdown />
                        </DropDown>
                      </NavItem>
                    )}
                  </ItemHover>
                  <ItemHover>
                    {hoverItem => (
                      <NavItem
                        color={hoverItem ? "#ED602B" : "white"}
                        rotate={hoverItem ? "rotate(45deg)" : "rotate(0deg)"}
                      >
                        Steel Buildings{" "}
                        <PlusIcon fill={hoverItem ? "#ED602B" : "#98dfff"} />
                        <DropDown
                          display={hoverItem ? "block" : "none"}
                          opacity={hoverItem ? "1" : "0"}
                        >
                          <OtherBuildingsDropdown />
                        </DropDown>
                      </NavItem>
                    )}
                  </ItemHover>
                  <ItemHover>
                    {hoverItem => (
                      <NavItem
                        color={hoverItem ? "#ED602B" : "white"}
                        rotate={hoverItem ? "rotate(45deg)" : "rotate(0deg)"}
                      >
                        Buying Guide{" "}
                        <PlusIcon fill={hoverItem ? "#ED602B" : "#98dfff"} />
                        <DropDown
                          display={hoverItem ? "block" : "none"}
                          opacity={hoverItem ? "1" : "0"}
                        >
                          <BuyingGuideDropdown />
                        </DropDown>
                      </NavItem>
                    )}
                  </ItemHover>
                  <ItemHover>
                    {hoverItem => (
                      <NavItem
                        color={hoverItem ? "#ED602B" : "white"}
                        rotate={hoverItem ? "rotate(45deg)" : "rotate(0deg)"}
                      >
                        About ProBuilt{" "}
                        <PlusIcon fill={hoverItem ? "#ED602B" : "#98dfff"} />
                        <DropDown
                          display={hoverItem ? "block" : "none"}
                          opacity={hoverItem ? "1" : "0"}
                        >
                          <AboutDropdown />
                        </DropDown>
                      </NavItem>
                    )}
                  </ItemHover>
                </NavGrid>
              </Nav>
            </HeaderInner>
          </DesktopNav>
          <MobileNav>
            <LogoLink to="/" aria-label="mobile logo">
              <img src={Logo} width="80" height="60" alt="mobile-logo" />
            </LogoLink>
            <MobileNavigation>
              <Link to="/shop-metal-buildings" className="shop"><img src={ShopGif} alt="Probuilt Steel Buildings" /></Link>
              {!hideSearch && <Link to="/search" aria-label="Search"><SearchIcon fill="#062C44" /></Link>}
              <a href="https://probuiltsteel.sensei3d.com/" target="_blank" aria-label="probuilt sensei 3d"><HouseIcon stroke="#062C44"  /></a>
              <a href="tel:8777541818" aria-label="contact number"><PhoneIcon fill="#062C44" /></a>
              <span onClick={this.handleMobileNav}><img src={MobileHamburger} alt="hamburger" width="24" height="17" /></span>
            </MobileNavigation>
            <MobileNavToggle
              transform={
                this.state.isVisibleMobileNav
                  ? "translateZ(0)"
                  : "translate3d(100%, 0px, 0px)"
              }
              width={
                this.state.isVisibleMobileNav
                  ? "300px"
                  : "0px"
              }
            >
              <MobileNavContainer>
                <CarportsMobileNav
                  isVisible={this.state.isVisibleCarports}
                  open={this.openCarports}
                  onChange={this.handleMobileNav}
                />
                <GaragesMobileNav
                  isVisible={this.state.isVisibleGarages}
                  open={this.openGarages}
                  onChange={this.handleMobileNav}
                />
                <RvCoversMobileNav
                  isVisible={this.state.isVisibleRvCovers}
                  open={this.openRvCovers}
                  onChange={this.handleMobileNav}
                />
                <BarnsMobileNav
                  isVisible={this.state.isVisibleBarns}
                  open={this.openBarns}
                  onChange={this.handleMobileNav}
                />
                <OtherBuildingsMobileNav
                  isVisible={this.state.isVisibleOtherBuildings}
                  open={this.openOtherBuildings}
                  onChange={this.handleMobileNav}
                />
                <BuyingGuideMobileNav
                  isVisible={this.state.isVisibleBuyingGuide}
                  open={this.openBuyingGuide}
                  onChange={this.handleMobileNav}
                />
                <AboutProbuiltMobileNav
                  isVisible={this.state.isVisibleAbout}
                  open={this.openAbout}
                  onChange={this.handleMobileNav}
                />
              </MobileNavContainer>
            </MobileNavToggle>
          </MobileNav>
        </div>
      </HeaderWrapper>
    )
  }
}

export default Header
