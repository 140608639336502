import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '../Icons/SearchIcon';
import { Link, navigate } from "gatsby"
import { DropSearch, DropSearchItem } from '../Section';
import { useSearch } from '../../utils/useSearch';
import { getProductUrl } from '../../utils/product';

const SearchForm = styled.div`
  background-color: #EEF8FF;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  form{
    position: relative;
    margin-bottom: 2px;

    input {
      width: 100%;
      border:1px solid #dce7ef;
      border-radius: 6px;
      padding: 2px 5px;
      &::placeholder {
        color:#004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color:#004485;
        }
      }
    }
    
    button {
      position: absolute;
      border: 0;
      outline: 0;
      background: none;
      right: 10px;
      top: 5px;
      display: block;
    }
  }
`

const HeaderSearchBox = () => {
  const [searchKey, setSearchKey] = useState("");
  const results = useSearch(searchKey);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchKey(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!results || results.length === 0) {
      navigate("/search", { state: { searchKey: searchKey } });
    }
  }

  const getLinkUrl = (product) => {
    return getProductUrl(product);
  }

  const getSearchItemTitle = (product) => {
    if (searchKey === product.skuNumber.toString()) return product.skuNumber;
    return product.title;
  }

  return (
    <Fragment>
      <SearchForm className='search-form'>
        <form onSubmit={handleSubmit}>
          <input
            className="form-control"
            type="text"
            placeholder="Search..."
            id="search"
            name="search"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
          <button type="submit"><SearchIcon /></button>
        </form>
      </SearchForm>
      <DropSearch className='drop-search'>
        {results.map((product, index) => (
          <DropSearchItem className='drop-search-item' key={index}>
            <Link to={getLinkUrl(product)}>
              {getSearchItemTitle(product)}
            </Link>
          </DropSearchItem>
        ))}
      </DropSearch>
    </Fragment>
  );
};

export default HeaderSearchBox;